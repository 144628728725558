<template>
    <my-layout class="monitor_nodata_main">
        <template #header>
            <div class="header">
                <span class="title">最新监测动态</span>
                <img src="../../assets/img/h5/h5_minilogo.png"/>
            </div>
        </template>
        <template #body>
            <div class="body">
                <van-empty
                    class="custom-image">
                    <template #image>
                        <img src="../../assets/img/h5/no_right.png"/>
                    </template>
                    <template #description>
                        <div>很抱歉，您暂时没有权限查看该信息。</div>
                        <div>请在电脑端绑定微信号后查看。</div>
                    </template>
                </van-empty>
            </div>
        </template>
    </my-layout>
</template>

<script>
  import { Empty } from 'vant';

  export default {
    name: "monitorNoData",
    components: {
      [Empty.name]: Empty,
    }
  }
</script>

<style lang="scss">
.monitor_nodata_main {
    .header {
        width: 100%;
        height: 170px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .title {
            font-size: 54px;
            color: #333333;
        }
        img {
            width: 86px;
            height: 73px;
            position: absolute;
            right: 45px;
        }
    }
    .body {
        .custom-image {
            .van-empty__image {
                width: 515px;
                height: 419px;
                margin-top: 366px;
            }
            .van-empty__description {
                text-align: center;
                font-size: 42px;
                color: #999999;
                line-height: 74px;
            }
        }
    }
}
</style>